import styled from 'styled-components';
import { CarrierItemWrapper } from './CarrierItem/CarrierItem.styles';

export const ListContainer = styled.div`
  ${CarrierItemWrapper}:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;
