import type { IDataSection } from '@/interfaces';
import { ListContainer } from './ItemList.styles';
import CarrierItem from './CarrierItem';

type ItemListProps = {
  data: IDataSection['carrierList'];
  hasLocation?: boolean;
};

const ItemList: React.FC<ItemListProps> = ({ data, hasLocation = true }) => {
  if (!data?.length) return null;

  return (
    <ListContainer>
      {data.map((carrier, idx) => (
        <CarrierItem key={idx} hasLocation={hasLocation} carrier={carrier} />
      ))}
    </ListContainer>
  );
};

export default ItemList;
