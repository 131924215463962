import { Flex } from '@/components/Common';
import { CompanyType, RouteName, theme } from '@/constants';
import { useGlobalState } from '@/hooks';
import type { IIndividualCarrier } from '@/interfaces';
import { getMarketSlugFromMarketName, replacePath } from '@/utils';
import Image from 'next/image';
import Link from 'next/link';
import AwardsIcon from 'public/images/icons/emoji_events.svg';
import Location from 'public/images/icons/location_on.svg'; // original location.svg is not centered
import {
  CarrierItemWrapper,
  Content,
  Description,
  FeaturedText,
  Headquarters,
  HeadquartersContainer,
  SpecialAwardsContainer,
  Title,
} from './CarrierItem.styles';

interface CarrierItemProps {
  hasLocation: boolean;
  carrier: Partial<IIndividualCarrier>;
}

const CarrierItem: React.FC<CarrierItemProps> = ({
  hasLocation = true,
  carrier: { carrierDisplayName, carrierName, carrierLogo, headquarters, featured, slug },
}) => {
  const { getProperImageUrl } = useGlobalState();

  if (!carrierDisplayName && !carrierName) return null;

  const marketSlug = getMarketSlugFromMarketName(headquarters || '', true);
  const marketHref = replacePath(RouteName.Market, [marketSlug]);

  return (
    <CarrierItemWrapper>
      <Flex>
        {!!carrierLogo?.url && (
          <Image
            src={getProperImageUrl(carrierLogo)}
            alt={carrierDisplayName || carrierName || 'Carrier Logo'}
            height={78}
            width={78}
            style={{
              objectFit: 'cover',
              marginRight: theme.spacing[16],
              borderRadius: 2,
            }}
          />
        )}
        <Content>
          <Link passHref href={replacePath(RouteName.IndividualCarrier, [CompanyType.carriers, slug])} legacyBehavior>
            <Title>{carrierDisplayName || carrierName}</Title>
          </Link>
          <Description>
            {featured && (
              <SpecialAwardsContainer>
                <AwardsIcon />
                <FeaturedText>Featured Carrier</FeaturedText>
              </SpecialAwardsContainer>
            )}
            {headquarters && hasLocation && (
              <HeadquartersContainer>
                <Location />
                <Link href={marketHref ?? '#'} passHref legacyBehavior>
                  <Headquarters>{headquarters}</Headquarters>
                </Link>
              </HeadquartersContainer>
            )}
          </Description>
        </Content>
      </Flex>
    </CarrierItemWrapper>
  );
};

export default CarrierItem;
