import { CarrierListMapping } from '@/constants';
import type { CarrierListTemplate, IDataSection } from '@/interfaces';
import ItemList from './ItemList';

type CarrierListProps = {
  data: IDataSection['carrierList'];
  hasLocation?: boolean;
  template?: CarrierListTemplate;
};

const CarrierList: React.FC<CarrierListProps> = ({ template, ...props }) => {
  if (!template) return <ItemList {...props} />;
  const Component = CarrierListMapping[template];
  if (!Component) return null;
  return <Component {...props} />;
};

export default CarrierList;
