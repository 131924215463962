import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';

export const Title = styled.a`
  ${styledText['Heading 3']}
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
    align-items: center;
  }
`;

export const HeadquartersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 324px;
  }
`;

export const Headquarters = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const SpecialAwardsContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.surface03};
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: 4px;
  margin-right: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  flex: none;

  svg {
    margin-right: ${({ theme }) => theme.spacing[4]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
  }
`;

export const FeaturedText = styled(Typography).attrs({
  variant: TextStyles['Heading 6'],
})`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
`;

export const CarrierItemWrapper = styled.div`
  word-break: break-word;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.pressedBlack};

  :hover {
    ${Title}, ${Headquarters} {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const Content = styled.div`
  overflow: hidden;
`;
